const StateData = {
    cards: [],
    histories: [],
    registered: false,
    config: {
        balance: 0,
        theme: 'light',
        account: {}
    },
};
export default StateData;
