const ReducerData = (state, action) => {
  switch (action.type) {
    case 'FETCH_CONFIG':
        return {
            
        }
    default:
        return state
  }
};
export default ReducerData;
