import React from "react";
import logo from "./logo.svg";
import "./styles/App.less";
import {Button, Result} from "antd";
import {Routes, Route, BrowserRouter, Navigate, Link} from "react-router-dom";
import Registration from "./routes/Registation";
import ContextData from "./context/Data/ContextData";
import ReducerData from "./context/Data/ReducerData";
import StateData from "./context/Data/StateData";
import Cookies from 'universal-cookie';

function App() {
    const [stateData, dispatchData] = React.useReducer(ReducerData, StateData);

    return (
        <div className={'mainAppContainer'}>
            <div className={'prapor'}/>
            <ContextData.Provider value={{stateData, dispatchData}}>
                <Registration/>
            </ContextData.Provider>
        </div>
    );
}

export default App;
