import {
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    message,
    InputNumber,
    Row,
    Select, Result, Alert, Typography, Tooltip, Popconfirm,
} from 'antd';
import {HighlightOutlined} from '@ant-design/icons';
import React, {useEffect, useState, useRef} from 'react';
import {PasswordInput} from "antd-password-input-strength";
import ContextData from "../../context/Data/ContextData";
import reCAPTCHA from "react-google-recaptcha"
import Cookies from 'universal-cookie';
import { EditOutlined } from '@ant-design/icons';

const cookies = new Cookies();

const {Option} = Select;

const REACT_APP_SITE_KEY = "6LfqxAchAAAAAFIkpvzePw18wns57jfy1YtqjgR2"//todo change
const REACT_APP_SECRET_KEY = "6LfqxAchAAAAANBgY6JuGrkEF6mZnT5FNiQdGhIs"//todo change

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const onKeyPress = (e) => {
    const digitCharRegex = new RegExp("[0-9]");
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!digitCharRegex.test(pressedKey) && pressedKey !== '+') {
        e.preventDefault();
        return false;
    }
}
const LIGHT_THEME = 'light';
const DARK_THEME = 'dark';
const auth_param = {
    email: 'test@example.ua',
    password: 'test'
};

const root_url = 'https://core.hillary.ua'
const new_partner_path = '/api/partner?'
const auth_token = '1|Ki3KamUOkk91KMjODWZpRCf2vo1Q6TwgjcvzW4J0'
/*let myHeaders = new Headers();
myHeaders.append('Authorization', 'Bearer ' + auth_token);*/
let requestOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + auth_token
    },
    redirect: 'follow',
};
const {Paragraph} = Typography;
const Registration = () => {
    const [form] = Form.useForm();
    const captchaRef = useRef(null)
    const {stateData} = React.useContext(ContextData);
    const [theme, setTheme] = React.useState(LIGHT_THEME);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [pass_gen, set_pass_gen] = React.useState(false);
    const [is_need_recovery_pass, set_is_need_recovery_pass] = React.useState(false);
    const [registered, setRegistered] = React.useState(false);
    const [registeredEmail, setRegisteredEmail] = React.useState('test@gmail.com');
    const altTheme = theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
    const [isShowEmailPopup, setIsShowEmailPopup] = React.useState(false);
    const [email, setEmail] = React.useState('')

    const applyTheme = (newTheme) => {
        document.getElementById('root').className = newTheme;
        stateData.config.theme = newTheme
        setTheme(newTheme);
    }

    const defaultValues = {
        prefix: '380',
        agreement3: true,
    }

    const onValuesChange = async (changed_values, all_values) => {
        setLoading(false)
    }
    const get_cookie = (name) => {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const onFinish = async (values) => {
        setLoading(true)
        setRegistered(false)
        /*const token = captchaRef.current.getValue();
        captchaRef.current.reset();*/
        console.log('values on Finish')
        console.log(values)
        setRegisteredEmail(values.email)
        let utm_metrics = {}
        const utm_names = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_content',
            'utm_term',
            'ref',
        ].map(utm => {
            utm_metrics[utm] = get_cookie(utm)
        })
        console.log('utm_metrics')
        console.log(utm_metrics)
        let get_params = localStorage.getItem('get_params')
        get_params = JSON.parse(get_params)
        console.log('get_params')
        console.log(get_params)
        get_params = {...get_params, ...utm_metrics}
        console.log(get_params)
        let new_partner_args = new URLSearchParams({
            ...auth_param, ...values,
            get_params: localStorage.getItem('get_params')
        })
        console.log('new_partner_args on Finish')
        console.log(new_partner_args)
        try {
            fetch(root_url + new_partner_path + new_partner_args, {
                ...requestOptions
            }).then(async response => {
                const is_json = response.headers.get('content-type')?.includes('application/json')
                const data = is_json ? await response.json() : null;
                console.log('RESPONSE OK?')
                console.log(response.ok)
                if (response.ok)
                    return data
                else {
                    if (response.status === 500) {
                        let error = (data && data.message) || response.status;
                        message.error(error)
                        setErrors([...errors, error])
                        return Promise.reject(error);
                    }
                    message.error('Помилка сервера ' + response.status)
                    return data
                }

            })
                .then(responseJson => {
                    if ('errors' in responseJson) {
                        if ('email' in responseJson['errors']) {
                            form.setFields([
                                {
                                    name: 'email',
                                    errors: ['Такий email вже зареєстровано'],
                                },
                            ]);
                            set_is_need_recovery_pass(true)
                        }
                        Object.values(responseJson.errors).map(error => {
                            console.log('responseJson.errors error')
                            console.log(error)
                            if (error[0] !== undefined && error[0] === 'Вказане значення поля e-mail адреса вже існує.')
                                error = 'Такий email вже зареєстровано'
                            message.error(error)
                            /*window.dataLayer.push({
                                'event': 'partner_registration_error',
                                'pagePath': window.location.href,
                                'pageTitle': 'Помилка: ' + error,
                                'visitorType': 'customer'
                            });*/
                        })
                        console.log('responseJson')
                        console.log(responseJson)
                        console.log(responseJson.errors)
                    } else {
                        message.success('Дякуємо! На Вашу електронну пошту відправлено лист з підтвердженням реєстрації');
                        cookies.set('registered', 'true', {path: '/'});
                        console.log("cookies.get('registered')");
                        console.log(cookies.get('registered'));
                        setRegistered(true)
                        set_is_need_recovery_pass(true)
                        form.resetFields()
                        window.dataLayer.push({
                            'event': 'partner_registration',
                            'pagePath': window.location.href,
                            'pageTitle': 'Відправлено листа з підтвердженням реєстрації',
                            'visitorType': 'customer'
                        });
                    }
                    setLoading(false)
                    return responseJson;
                })
        } catch (e) {
            alert('error')
            /*window.dataLayer.push({
                'event': 'partner_registration_error',
                'pagePath': window.location.href,
                'pageTitle': 'Помилка: ' + e,
                'visitorType': 'customer'
            });*/
            console.log(e)
            setLoading(false)
        }
        console.log('Received values of form: ', values);
    };
    const onChangePhone = (value) => {
        console.log('onChangePhone: ', value);
        console.log('onChangePhoneTheme: ', altTheme);
        let inputted_value = value.target.value
        console.log(inputted_value)
        let phone = '+' + inputted_value.replace(/[^\d]/g, '');
        if (phone.match(/^(\+7)/))
            applyTheme('dark')
        else
            applyTheme('light')
        form.setFieldsValue({phone: phone})
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            +
        </Form.Item>
    );

    const get_random_int = (max) => {
        return Math.floor(Math.random() * max);
    }

    const onClickRepairEmail = () => {
        form.setFieldsValue({
            email: repairEmail(email)
        })
        setIsShowEmailPopup(false)
    }

    const password_generate = () => {
        let get_rand_from_arr = (array) => {
            return array[Math.floor(Math.random() * array.length)]
        }
        let dictionary_alpha_upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        let dictionary_alpha_lower = 'abcdefghijklmnopqrstuvwxyz'
        let dictionary_symbols = '@$!%*?&#/|~<>^{}[]():;'
        const password = get_rand_from_arr(dictionary_alpha_upper)
            + get_rand_from_arr(dictionary_alpha_lower)
            + get_rand_from_arr(dictionary_alpha_lower)
            + get_rand_from_arr(dictionary_alpha_lower)
            + get_rand_from_arr(dictionary_alpha_upper)
            + get_rand_from_arr(dictionary_alpha_upper)
            + get_rand_from_arr(dictionary_alpha_lower)
            + get_random_int(999)
            + get_rand_from_arr(dictionary_symbols)
            + get_rand_from_arr(dictionary_alpha_upper)
            + get_rand_from_arr(dictionary_symbols.split(''))
        set_pass_gen(password)
        const fields = [
            {
                name: 'password',
                value: password,
            }, {
                name: 'confirm',
                value: password,
            },
        ]
        form.setFields(fields);
        form.validateFields();
        return password
    }

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    const repairEmailBeforeDot = [
        { target: 'email', repaired: 'gmail' },
        { target: 'mail', repaired: 'gmail' },
        { target: 'gmal', repaired: 'gmail' },
        { target: 'gma', repaired: 'gmail' },
        { target: 'gmi', repaired: 'gmail' },
        { target: 'gmai', repaired: 'gmail' },
        { target: 'gmall', repaired: 'gmail' },
        { target: 'gnail', repaired: 'gmail' },
        { target: 'gmial', repaired: 'gmail' },
        { target: 'gimail', repaired: 'gmail' },
        { target: 'clod', repaired: 'cloud' },
        { target: 'claud', repaired: 'cloud' }
    ];

    const repairEmailAfterDot = [
        { target: 'kom', repaired: 'com' },
        { target: 'con', repaired: 'com' },
    ];

    const repairEmailAfterDog = [
        { target: 'gmail.com.ua', repaired: 'gmail.com' },
        { target: 'gmail.ru', repaired: 'gmail.com' },
        { target: 'gmail.co', repaired: 'gmail.com' },
        { target: 'gmail.ua', repaired: 'gmail.com' }
    ];
    const replaceDomainParts = (part, replacements) => {
        for (const { target, repaired } of replacements) {
            if (part === target) {
                return repaired;
            }
        }
        return part;
    };

    const repairEmail = (email) => {
        // Розділяємо email на частини
        const [localPart, domainPart] = email.split('@');
        if (!domainPart) return email; // Якщо email не містить '@', повертаємо як є

        // Розділяємо domainPart на частини до і після крапки
        const [beforeDot, afterDot] = domainPart.split('.');

        // Застосовуємо заміни
        const repairedBeforeDot = replaceDomainParts(beforeDot, repairEmailBeforeDot);
        const repairedAfterDot = replaceDomainParts(afterDot, repairEmailAfterDot);
        const repairedDomainPart = `${repairedBeforeDot}.${repairedAfterDot}`;

        // Перевіряємо і виправляємо домен після '@'
        const finalDomainPart = replaceDomainParts(repairedDomainPart, repairEmailAfterDog);

        return `${localPart}@${finalDomainPart}`;
    };
    const onChangeEmail = (e) => {
        const email = e.target.value
        console.log('onChangeEmail: ', email);
        console.log('onValidEmail', isValidEmail(email))
        setEmail(email)
        console.log('repairEmail(email)', repairEmail(email))
        if(isValidEmail(email) && email !== repairEmail(email)) {
            setIsShowEmailPopup(true)
        }else{
            setIsShowEmailPopup(false)
        }
    }
    return (
        <Form
            layout={'vertical'}
            form={form}
            name="register"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={defaultValues}
            scrollToFirstError
        >
            <a href={'https://drop.hillary.ua/'}>
                <div className={'logoForm'}/>
            </a>
            <Form.Item
                name="name"
                label="Ім'я та Прізвище"
                rules={[
                    {
                        required: true,
                        message: 'Будь ласка, введіть ваше ім\'я та прізвище!',
                        whitespace: true,
                    }
                ]}
            >
                <Input onChange={(e) => {
                    const str = e.target.value;
                    let cleared = str.replace(/[^a-zA-Zа-яА-Я ґіїєҐІЇЄ']/g, "")
                    cleared = cleared.replace('  ', '')
                    console.log(cleared)
                    form.setFieldsValue({
                        name: cleared
                    })
                }}/>
            </Form.Item>
            <Form.Item
                onChange={onChangeEmail}
                name="email"
                label="Email"
                rules={[
                    {
                        type: 'email',
                        message: 'Введений недійсний E-mail!',
                    },
                    {
                        required: true,
                        message: 'Будь ласка, введіть свій E-mail!',
                    },
                ]}
            >

                <Input type={'email'}/>
            </Form.Item>
            {isShowEmailPopup?<><Alert message={<div className={'alert_column'}><div>Шось не так з поштою, можливо ви мали на увазі <b>{repairEmail(email)}</b></div><Button onClick={onClickRepairEmail} size={'small'} type={'primary'} icon={<EditOutlined />}>Виправити</Button>
            </div>} type="warning" showIcon closable/><br/></>:''}

            <Alert className={is_need_recovery_pass ? 'recovery_alert' : 'danone'} message={
                <>
                    Для того щоб відновити доступ до особистого кабінету скористайтесь
                    &nbsp; <a className={'password_reset_link'}
                              href={"https://hillary.affise.com/v2/sign/password/reset"} target={'_blank'}>формою
                    відновлення паролю</a>
                </>
            } type="info" showIcon/>
            <Form.Item
                name="phone"
                label="Телефон"
                onChange={onChangePhone}
                rules={[
                    {
                        required: true,
                        message: 'Будь ласка, введіть свій номер телефону!',
                    },
                    {
                        message: 'Довжина телефону не може бути менш 13 сімволів та більш 15',
                        pattern: /^.{13,15}$/
                    }
                ]}
            >
                <Input
                    onKeyPress={onKeyPress}
                    type={'tel'}
                    style={{
                        width: '100%',
                    }}
                />
            </Form.Item>
            <Form.Item
                name="telegram"
                label="Telegram"
                tooltip="Ваш телеграм нікнейм"
                rules={[
                    {
                        required: true,
                        message: 'Будь ласка, введіть свій нікнейм!',
                        whitespace: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="password"
                label="Пароль"
                tooltip="Пароль має бути від 8 до 30 символів, серед яких мінімум по одній маленькій та великій латинській літері, мінімум одна цифра та мінімум один із знаків @$!%*?&#/|\~<>^{}[]():;"
                /*rules={[{
                    validator: async () => {
                        return level >= minLevel ? Promise.resolve() : Promise.reject(errorMessage);
                    },
                    message: errorMessage
                }]}*/
                rules={[{
                    message: '• Пароль має бути не менше 8 символів, але не більше 30',
                    pattern: /^.{8,30}$/
                }, {
                    message: '• Пароль має містити принаймні один спеціальний символ: _-!@*.$%?&#\\/|\\\\~<>^{}[]():;',
                    pattern: /(?=.*[_\-!@*.$%?&#\/|\\~<>^{}\[\]():;])/
                }, {
                    message: '• Пароль має містити принаймні один цифровий символ',
                    pattern: /(?=.*?[0-9])/
                }, {
                    message: '• Пароль має містити принаймні одну латинську літеру нижнього регістру',
                    pattern: /(?=.*[a-z])/
                }, {
                    message: '• Пароль має містити принаймні одну латинську літеру верхнього регістру',
                    pattern: /(?=.*[A-Z])/
                }, {
                    message: '• Пароль не може містити кирилицю',
                    pattern: /^[^\u0400-\u04FF]*$/
                }, {
                    message: '• Пароль не може містити пробіл або інші керуючі символи',
                    pattern: /^[^\s]*$/
                }, {
                    message: '• Пароль може містити лише такі спеціальні символи: _-!@*.$%?&#/|\\~<>^{}[]():;',
                    pattern: /^[A-Za-z\d-_!@*.$%?&#/|\~<>^{}[\]():;]*$/
                }
                ]}
                hasFeedback
            >
                <Input.Password type={'password'}/>
            </Form.Item>
            <Form.Item
                name="confirm"
                label="Підтвердження паролю"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Будь ласка, підтвердьте пароль!',
                        whitespace: true,
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error('Паролі, які ви ввели, не збігаються!'));
                        },
                    }),
                ]}
            >
                <Input.Password type={'password'}/>
            </Form.Item>
            <Tooltip placement="top" title={'Натисніть, щоб згенерувати надійний пароль'}>
                <div className={'password_generator_block'}>
                    <Button size="small" type="link" icon={<HighlightOutlined/>} onClick={password_generate}
                            className={'pass_gen_button'}>Згенерувати пароль</Button>
                    {pass_gen ? <Paragraph copyable>{pass_gen}</Paragraph> : ''}
                </div>
            </Tooltip>

            {/*<reCAPTCHA
                    sitekey={REACT_APP_SITE_KEY}
                    ref={captchaRef}
                />*/}
            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Потрібно ознайомитись')),
                    },
                ]}
            >
                <Checkbox>
                    Я ознайомився/ознайомилась з <a href="https://drop.hillary.ua/instructions" target={'_blank'}>Правилами
                    партнерської програми</a>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="agreement2"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Потрібно прийняти згоду')),
                    },
                ]}
            >
                <Checkbox>
                    Я даю згоду на обробку та використання інформації про мене або мою компанію.
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="agreement3"
                valuePropName="checked"
            >
                <Checkbox>
                    Я погоджуюсь отримувати важливі інформаційні розсилки від Партнерської програми HiLLARY.
                </Checkbox>
            </Form.Item>
            {errors.map((error) => {
                return <Alert message={error} className={'alert_error'} type="error" showIcon/>
            })}
            <Form.Item>
                <Button className={'buttonRegistration'} type="primary" htmlType="submit" loading={loading}>
                    Зареєструватись
                </Button>
            </Form.Item>
            {registered ? <div className={'modal_window'}>
                <Result
                    status="warning"
                    title="Дякуємо!"
                    subTitle={<div className={'result_subtitle'}>
                        <div className={'registered_block'}>На Вашу електронну пошту <b
                            className={'registered_email'}>{registeredEmail}</b> відправлено лист з підтвердженням
                            реєстрації.
                        </div>
                        <Alert className={'result_alert'}
                               message="Якщо листа немає - перевірте правильність вводу електронної пошти та папку Спам."
                               type="info" showIcon/>
                        <Alert className={'result_alert'}
                               message="У випадку помилки - зареєструйтесь ще раз, вказавши вірну адресу електронної пошти."
                               type="warning" showIcon/>
                    </div>}
                    extra={[
                        <Button onClick={() => {
                            //window.history.back() todo change to https://drop.hillary.ua/
                            window.location.href = 'https://drop.hillary.ua/'
                        }}>Повернутись на сайт</Button>,
                    ]}
                />
            </div> : <></>}
        </Form>
    );
};

export default Registration;
